import { useState } from "react";
import useForm from "./useForm";

const ContactForm = ({ subject }) => {
  const [values, setValues] = useState({
    subject,
    mobile: "",
    name: "",
    email: "",
    text: "",
  });

  const {
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    emptyContacts,
    checkErrors,
  } = useForm(subject, setValues, values);

  return (
    <div style={{ gridArea: "contactForm" }}>
      <h2 className="text-xl font-semibold mb-4">Tem alguma dúvida?</h2>
      <form className="info-form flex-col space-y-2 mb-4">
        <div className="flex">
          <label htmlFor="subject">Assunto</label>
          <input
            className="grow"
            value={values.subject}
            onChange={handleChange}
            type="text"
            name="subject"
            id="subject"
          />
        </div>
        <div className="flex gap-2 flex-wrap">
          <div className="flex grow">
            <label htmlFor="name">Nome</label>
            <input
              className="grow"
              value={values.name}
              onChange={handleChange}
              type="text"
              name="name"
              placeholder="Nome"
            />
          </div>
          <div className="flex grow">
            <label htmlFor="mobile">Contacto</label>
            <input
              className="grow"
              value={values.mobile}
              onChange={handleChange}
              placeholder="Contacto"
              type="text"
              name="mobile"
              id="mobile"
            />
          </div>
          <div className="flex grow">
            <label htmlFor="email">Email</label>
            <input
              className="grow"
              value={values.email}
              onChange={handleChange}
              type="email"
              name="email"
              placeholder="Email"
            />
          </div>
        </div>
        <div>
          <label htmlFor="text">Mensagem</label>
          <textarea
            className="block w-full mt-2"
            value={values.text}
            onChange={handleChange}
            type="text"
            name="text"
            placeholder="Pedido de informações"
          ></textarea>
        </div>
        {checkErrors(errors, "text")}
        {checkErrors(errors, "name")}

        {emptyContacts && (
          <label className="block text-red-500">
            Se não preencher um contacto de e-mail ou móvel válido, não será
            possível contactá-lo de volta.
          </label>
        )}

        <button
          className={`${
            isSubmitting
              ? "button bg-slate-400 animate-pulse"
              : "button-primary"
          }`}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          Enviar
        </button>
        {checkErrors(errors, "server")}
      </form>
    </div>
  );
};

export default ContactForm;
