import { useState, useEffect } from "react";
import _ from "lodash";
import { ThemeProvider } from "styled-components";
import axios from "axios";
import LoadingSpinner, { LoadingContainer } from "./common/loading";
import { beUrl } from "../config";
import Pagination from "./pagination";
import CarListItem from "./car-list-item";
import Filter from "./filter";

const CarList = (props) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showSold, setShowSold] = useState(true);
  const [expanded, setExpanded] = useState(window.innerWidth > 1024);
  const [cars, setCars] = useState(undefined);

  const carsPerPage = 12;

  useEffect(() => {
    const fetchData = async () => {
      const { data: cars } = await axios.get(`${beUrl}/cars`);
      setCars(_.sortBy(cars, ["sold", "attributes.brand.value"]));
    };

    fetchData();
  }, []);

  const applyFilters = () => {
    let filtered = cars;

    filtered =
      selectedBrands.length === 0
        ? cars
        : filtered.filter((car) =>
            selectedBrands.includes(car.attributes.brand.value)
          );

    filtered = showSold ? filtered : filtered.filter((car) => !car.sold);

    return filtered;
  };

  const getVisibleCars = (selectedPage) => {
    const i = (selectedPage - 1) * carsPerPage;
    const f = i + carsPerPage;

    return applyFilters().slice(i, f);
  };

  const handleSelectPage = (n) => {
    setSelectedPage(n);
  };

  const handleClickBrand = (brand) => {
    if (brand === "all") {
      setSelectedBrands([]);
      return;
    }

    let brands = [...selectedBrands];

    if (!selectedBrands.includes(brand)) {
      brands.push(brand);
    } else {
      brands = brands.filter((b) => b !== brand);
    }

    setSelectedBrands(brands);
    setSelectedPage(1);
  };

  const handleDisableAvailableFilter = () => {
    setShowSold(true);
    setSelectedPage(1);
  };

  const handleClickAvailable = () => {
    setShowSold(false);
    setSelectedPage(1);
  };

  const handleToggleVisibility = () => {
    setExpanded(!expanded);
  };

  if (!cars) {
    return (
      <LoadingContainer>
        <LoadingSpinner></LoadingSpinner>
      </LoadingContainer>
    );
  }
  const filteredCars = applyFilters();
  const nPages = Math.ceil(filteredCars.length / carsPerPage);
  const selectedPg = selectedPage > nPages ? nPages : selectedPage;

  return (
    <>
      <ThemeProvider theme={props.theme}>
        <div className="flex flex-col sm:flex-row gap-4 mx-2">
          <Filter
            theme={props.theme.filters}
            onDisableAvailableFilter={handleDisableAvailableFilter}
            onClickAvailable={handleClickAvailable}
            onClickBrand={handleClickBrand}
            selectedBrands={selectedBrands}
            showSold={showSold}
            toggleVisibility={handleToggleVisibility}
            expanded={expanded}
          />

          <div className="flex-col">
            <div className="car-grid gap-3">
              {getVisibleCars(selectedPg).map((car) => (
                <CarListItem theme={props.theme} key={car.id} car={car} />
              ))}
            </div>

            <div className="my-4">
              <Pagination
                theme={props.theme.pagination}
                numberOfPages={nPages}
                selectedPage={selectedPg}
                onSelectPage={handleSelectPage}
              />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default CarList;
