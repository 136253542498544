import { useState } from "react";
import axios from "axios";
import { beUrl } from "../../config";
import { useHistory } from "react-router-dom";
import { toast } from "../common/helper";

const LoginForm = (props) => {
  const [user, setUser] = useState({ email: "", password: "" });
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(`${beUrl}/auth`, user)
      .then(({ data: jwt }) => {
        localStorage.setItem("token", jwt);
        history.push("/admin/list");
      })
      .catch((err) => {
        toast.error("Dados de login incorrectos");
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    const u = { ...user };
    u[input.name] = input.value;
    setUser(u);
  };

  return (
    <div className="p-4">
      <form className="flex flex-col gap-2">
        <div className="form-group">
          <div className="form-row">
            <label htmlFor="email">Utilizador</label>
            <input
              value={user.email}
              onChange={handleChange}
              type="email"
              name="email"
              id="email"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-row">
            <label htmlFor="password">Password</label>
            <input
              value={user.password}
              onChange={handleChange}
              type="password"
              name="password"
              id="password"
              className="form-control"
            />
          </div>
        </div>

        <button className="button-primary self-start" onClick={handleSubmit}>
          Entrar
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
