import "./output.css";
import { Route, Switch, Redirect } from "react-router-dom";
import CarList from "./components/car-list";
import CarDetails from "./components/CarDetails/car-details";
import NotFound from "./components/not-found";
import { Component } from "react";
import NavBar from "./components/navbar";
import Home from "./components/home";
import { theme } from "./themes";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import Inventory from "./components/Admin/Inventory";
import EditForm from "./components/Admin/EditForm";
import AddForm from "./components/Admin/AddForm";
import LoginForm from "./components/Admin/Login";
import { ToastContainer } from "react-toastify";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.bgColor};
  }
  `;

class App extends Component {
  state = {};

  render() {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ToastContainer limit={0} />
        <NavBar onClickThemeToggle={this.toggleTheme} />

        <div className="content">
          <Switch>
            <Route
              path="/admin/list"
              render={(props) => <Inventory theme={theme} {...props} exact />}
            />
            <Route
              path="/admin/edit/:id"
              render={(props) => <EditForm theme={theme} {...props} exact />}
            />
            <Route
              path="/admin/new"
              render={(props) => <AddForm theme={theme} {...props} exact />}
            />
            <Route
              path="/admin/login"
              render={(props) => <LoginForm theme={theme} {...props} exact />}
            />
            <Route
              path="/carro/:id"
              render={(props) => <CarDetails theme={theme} {...props} />}
            />
            <Redirect from="/admin" to="/admin/list" />
            <Redirect from="/cars" to="/carros" />
            <Redirect from="/car/:id" to="/carro/:id" />
            <Redirect from="/carros/:id" to="/carro/:id" />
            <Route
              path="/carros"
              render={(props) => <CarList theme={theme} {...props} />}
            />
            <Route
              path="/not-found"
              render={(props) => <NotFound theme={theme} {...props} />}
            />
            <Route
              exact
              path="/"
              render={(props) => <Home theme={theme} {...props} />}
            />
            <Redirect to="/not-found" />
          </Switch>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
