
const Details = (props) => {
  return (
    <div className="mb-[70px] mx-3 text-center">
      <i className="fas fa-calendar-alt mr-2"></i>
      {props.year}
      <i className="fas fa-road mr-2 ml-2"></i>
      {props.mileage.value} {props.mileage.description}
      <i className="fas fa-gas-pump mr-2 ml-2"></i>
      {props.fuelType}
    </div>
  );
};

export default Details;
