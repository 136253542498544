import LinkableSquare from "./linkableSquare";

export const GridContacts = () => {
  return (
    <div>
      <h2 className="text-xl mb-2">Contactos:</h2>
      <div className="flex flex-wrap gap-2">
        <LinkableSquare
          link="https://goo.gl/maps/9HX84yQE545n2Mkq8"
          label="Avenida Barbosa Du Bocage nº 98 4º Esq, Lisboa"
          faIcon="fas fa-map-marker-alt"
        ></LinkableSquare>

        <LinkableSquare
          link="https://www.instagram.com/vinculobjetivo/"
          label="Instagram @vinculobjetivo"
          faIcon="fa-brands fa-instagram"
        ></LinkableSquare>

        <LinkableSquare
          link="tel:21 796 0163"
          label="+351 21 796 0163"
          faIcon="fas fa-phone"
        ></LinkableSquare>

        <LinkableSquare
          link="tel:+351961589685"
          label="+351 961 589 685"
          faIcon="fas fa-phone"
        ></LinkableSquare>

        <LinkableSquare
          link="mailto:vinculobjetivo@gmail.com"
          label="vinculobjetivo@gmail.com"
          faIcon="fas fa-envelope"
        ></LinkableSquare>
      </div>
    </div>
  );
};
