const LinkableSquare = ({ link, faIcon, classes, label }) => {
  return (
    <a
      href={link}
      target="_black"
      className={`text-xl border border-black rounded p-2 hover:bg-slate-300 ${
        faIcon.includes("instagram") &&
        "hover:bg-gradient-to-r from-[#405de6] via-[#5851db] via-[#833ab4] via-[#c13584] via-[#e1306c] to-[#fd1d1d] hover:text-white"
      }`}
    >
      <i className={`mr-2 ${faIcon}`}></i>
      <span>{label}</span>
    </a>
  );
};

export default LinkableSquare;
