import { useHistory } from "react-router-dom";

export const Services2 = ({handleOpenModal}) => {
  const animation = "transition duration-300 ease-out hover:ease-in-out";
  const history = useHistory();

  return (
    <section className="bg-services-background flex flex-col items-center p-6">
      <h2 className="homeTitle text-services-primary">Serviços</h2>
      <h3 className="homeSubtitle">
        Encontre aqui um bom negócio para o seu próximo carro.
      </h3>
      <div className="inline-grid lg:w-[80%] lg:max-w-[1600px] lg:grid-cols-2 grid-cols-1 w-full justify-items-center gap-3">
        <div
          className={`py-3 pl-[5rem] pr-[1rem] bg-services-cardBg ${animation} hover:text-white group hover:bg-services-bgHover hover:cursor-pointer w-[65%] lg:w-full rounded-xl relative overflow-hidden`}
          onClick={() => history.push("carros")}
        >
          <h4 className="text-lg font-semibold text-services-primary group-hover:text-white">Venda de carros usados</h4>
          <p>
            Vendemos todo o tipo de viaturas usadas a preços acessíveis a todos.
          </p>
          <i className="text-services-primary group-hover:text-white fas fa-car absolute text-[4rem] -left-3 top-[calc(50%-2rem)]"></i>
        </div>
        <div
          className={`py-3 pl-[5rem] pr-[1rem] bg-services-cardBg ${animation} hover:text-white group hover:bg-services-bgHover hover:cursor-pointer w-[65%] lg:w-full rounded-xl relative overflow-hidden`}
          onClick={handleOpenModal}
        >
          <h4 className="text-lg font-semibold text-services-primary group-hover:text-white">Reparações em casa</h4>
          <p>Fazemos todo o tipo de reparações na sua habitação.</p>
          <i className="text-services-primary group-hover:text-white fas fa-house-damage absolute text-[4rem] -left-3 top-[calc(50%-2rem)]"></i>
        </div>
        <div
          className={`py-3 pl-[5rem] pr-[1rem] bg-services-cardBg ${animation} hover:text-white group hover:bg-services-bgHover hover:cursor-pointer w-[65%] lg:w-full rounded-xl relative overflow-hidden`}
          onClick={handleOpenModal}
        >
          <h4 className="text-lg font-semibold text-services-primary group-hover:text-white">Peças automóvel</h4>
          <p>Vendemos todo o tipo de peças para automóveis.</p>
          <i className="text-services-primary group-hover:text-white fas fa-wrench absolute text-[4rem] -left-3 top-[calc(50%-2rem)]"></i>
        </div>
        <div
          className={`py-3 pl-[5rem] pr-[1rem] bg-services-cardBg ${animation} hover:text-white group hover:bg-services-bgHover hover:cursor-pointer w-[65%] lg:w-full rounded-xl relative overflow-hidden`}
          onClick={handleOpenModal}
        >
          <h4 className="text-lg font-semibold text-services-primary group-hover:text-white">Aconselhamento automóvel</h4>
          <p>Prestamos ajuda e aconselhamento automóvel.</p>
          <i className="text-services-primary group-hover:text-white fas fa-comments absolute text-[4rem] -left-3 top-[calc(50%-2rem)]"></i>
        </div>
      </div>
    </section>
  );
};
