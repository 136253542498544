import { formatKms } from "../common/helper";
import { Price } from "./styled-components";

const GetCarSpecsTable = (car) => {
  return (
    <table className="w-full">
      <tbody>
        {Object.keys(car.attributes).map((key) => {
          return (
            <tr key={key} className="border border-b-slate-400">
              <th className="text-left align-top p-2">{car.attributes[key].description}</th>
              <td className="text-right p-2">
                {key === "kms"
                  ? formatKms(car.attributes[key].value)
                  : car.attributes[key].value}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const getBadge = (car) => {
  return car.sold ? (
    <h2>
      <span className="text-2xl rounded p-2 font-semibold bg-vinculo-paleOrange">Vendido</span>
    </h2>
  ) : (
    <h2>
      <Price className="text-2xl rounded p-2 font-semibold bg-vinculo-pumpkin">{car.price}€</Price>
    </h2>
  );
};

export { GetCarSpecsTable, getBadge };
