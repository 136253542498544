import { toast as t } from "react-toastify";

export const formatKms = (k) =>
  k.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " KMs";

export const formatPrice = (k) =>
  k.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "€";

const optionsError = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const optionsSuccess = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const toast = {
  error: (msg) => {
    t.error(msg, optionsError);
  },
  success: (msg) => {
    t.success(msg, optionsSuccess);
  },
};

export const userLogged = () => {
  return localStorage.getItem("token");
};
