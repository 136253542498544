import { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import axios from "axios";
import LoadingSpinner, { LoadingContainer } from "../common/loading";
import { toast } from "../common/helper";
import { beUrl } from "../../config";
import { Link } from "react-router-dom";
import { removeStyle } from "../../styles/links";

import {
  FullscreenToggle,
  BackToList,
  Error404,
  Grid,
} from "./styled-components";
import { GetCarSpecsTable, getBadge } from "./car-details-funtions";
import Carousel from "./carousel";
import ContactForm from "../form/contact-form";

function CarDetails(props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const [car, setCar] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        let { data: c } = await axios.get(
          `${beUrl}/cars/${props.match.params.id}`
        );

        if (!c) {
          c = -1;
        }

        setCar(c);

        if (c.sold) {
          toast.error("⚠️ Esta viatura já foi vendida 😢");
        }
      } catch {
        setCar(-1);
      }
    }
    fetchData();
  }, [props.match.params.id]);

  const { id } = props.match.params;

  const handleScrollToEnd = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const toggleFullscreen = () => {
    setFullscreen((prev) => !prev);
    handleScrollToEnd();
  };

  if (!car) {
    return (
      <LoadingContainer>
        <LoadingSpinner></LoadingSpinner>
      </LoadingContainer>
    );
  }

  if (car === -1) {
    return (
      <ThemeProvider theme={props.theme}>
        <Error404>
          <span>Erro:</span> Viatura não existe: id {id}
        </Error404>
      </ThemeProvider>
    );
  }

  return (
    <>
      <ThemeProvider theme={props.theme}>
        <FullscreenToggle onClick={toggleFullscreen}>
          <i className={fullscreen ? "fas fa-compress" : "fas fa-expand"}></i>
        </FullscreenToggle>
        {!fullscreen ? (
          <div>
            <Link to="/carros" style={{ ...removeStyle }}>
              <BackToList>
                <i className="fas fa-arrow-left"></i> Catálogo
              </BackToList>
            </Link>
            <Grid>
              <Carousel
                fullscreen={fullscreen}
                car={car}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />

              <div
                style={{ gridArea: "sideDetails" }}
                className="flex flex-col gap-4"
              >
                <h1 className="text-3xl break-words">{car.title}</h1>
                <div>{getBadge(car)}</div>
                <div>{GetCarSpecsTable(car)}</div>
              </div>

              <ContactForm
                theme={props.theme}
                subject={car.title}
              ></ContactForm>
            </Grid>
          </div>
        ) : (
          <Carousel
            fullscreen={fullscreen}
            car={car}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        )}
      </ThemeProvider>
    </>
  );
}

export default CarDetails;
