import PropTypes from "prop-types";

const Pagination = ({ selectedPage, numberOfPages, onSelectPage }) => {

  const lastPage = selectedPage === numberOfPages;
  const firstPage = selectedPage === 1;

  let nextPage = lastPage ? selectedPage : selectedPage + 1;

  let previousPage = firstPage ? selectedPage : selectedPage - 1;

  return (
    <div className="flex gap-1">
      <button
        className={`button-primary ${firstPage && "button-disabled"}`}
        onClick={() => onSelectPage(previousPage)}
      >
        Anterior
      </button>
      {Array.from(Array(numberOfPages).keys(), (x) => x + 1).map((index) => (
        <button
          key={`page${index}`}
          className={`button ${index === selectedPage ? "bg-vinculo-pumpkin" : "bg-vinculo-rustyOrange"} text-white`}
          onClick={() => onSelectPage(index)}
        >
          {index}
        </button>
      ))}
      <button
        className={`button-primary ${lastPage && "button-disabled"}`}
        onClick={() => onSelectPage(nextPage)}
      >
        Próximo
      </button>
    </div>
  );
};

Pagination.propTypes = {
  selectedPage: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  onSelectPage: PropTypes.func.isRequired,
};

export default Pagination;
