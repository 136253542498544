import styled from "styled-components";
import { devices } from "../../devices";

export const Grid = styled.div`
  maxWidth: 100vw;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-columns: 7fr 3fr;
  grid-template-areas:
    "carousel sideDetails"
    "contactForm sideDetails"
    "nothing adsense";

  @media ${devices.laptop} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "carousel"
      "sideDetails"
      "adsense"
      "contactForm";
  }
`;

const FullscreenToggle = styled.span`
  position: fixed;
  z-index: 999999999999;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  font-size: 30px;
  color: white;
  padding: 5px 13px;
  border-radius: 50%;
  text-align: center;
  background-color: ${(props) => props.theme.badge.bg};
  border: 4px solid transparent;

  &:hover {
    background-color: orange;
  }
`;

const DetailsList = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  color: ${(props) => props.theme.primaryText};
`;

const Price = styled.span`
  background-color: ${(props) => props.theme.badge.bg};
  color: ${(props) => props.theme.badge.fg};
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.primaryText};
`;

const Error404 = styled.div`
  margin: 50px auto;
  color: ${(props) => props.theme.bgColor};
  -webkit-filter: invert(100%);
  filter: invert(100%);
  font-size: 40px;
  text-align: center;

  & span {
    font-size: 1.4em;
    border-bottom: 3px solid red;
  }
`;

const BackToList = styled.h5`
  margin: 20px 0px 20px 20px;
  display: inline-block;
  padding: 7px;
  background-color: ${(props) => props.theme.badge.bg};
  color: ${(props) => props.theme.badge.fg};
  border-radius: 5px;
  border: 2px solid transparent;

  &:hover {
    border-color: black;
  }
`;

export { FullscreenToggle, BackToList, H1, Error404, DetailsList, Price };
