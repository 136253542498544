import { GridContacts } from "./common/contact-links";

const ContactUs = (props) => {
  return (
    <div className="p-6 bg-white">
      <h2 className="homeTitle">Contacte-nos</h2>
      <h3 className="homeSubtitle">Saiba onde nos encontrar e veja os nossos contactos.</h3>

      <div className="row" id="contacts">
        <div className="col-lg-6">
          <div className="map mb-4 mb-lg-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.0241002903394!2d-9.149977184157171!3d38.74020917959513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd193309a36b00df%3A0x194e12026d5b6ecd!2sAv.%20Barbosa%20Du%20Bocage%2098%2C%201050-062%20Lisboa!5e0!3m2!1sen!2spt!4v1591018387837!5m2!1sen!2spt"
              width="600"
              height="450"
              style={{ border: "0", width: "100%", height: "312px" }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="map"
            ></iframe>
          </div>
        </div>

        <div className="col-lg-6">{GridContacts()}</div>
      </div>
    </div>
  );
};

export default ContactUs;
