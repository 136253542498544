import { v4 as uuidv4 } from "uuid";

const DynamicAttributes = ({ attributes, setAttributes }) => {
  const handleChange = ({ currentTarget: input }) => {
    const att = { ...attributes };
    att[input.getAttribute("data-key")][input.name] = input.value;
    setAttributes(att);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const att = { ...attributes };
    att[uuidv4()] = {
      value: "",
      description: "",
    };
    setAttributes(att);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const att = { ...attributes };
    delete att[e.currentTarget.getAttribute("data-key")];
    setAttributes(att);
  };

  const isUUID = (key) =>
    /^[0-9A-Z]{8}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{12}$/i.test(key);

  return (
    <div>
      <h3 className="text-lg font-semibold mb-2">Detalhes</h3>
      <div  className="flex flex-col gap-2 mb-2">
        {Object.keys(attributes).map((key) => {
          return isUUID(key) && (
            <div key={key} className="flex gap-2 flex-col sm:flex-row bg">
              <label className="self-center">Descrição</label>
              <input
              className="p-1"
                type="text"
                data-key={key}
                value={attributes[key].description}
                name="description"
                onChange={handleChange}
              />

              <label className="self-center">Valor</label>
              <input
              className="p-1"
                type="text"
                data-key={key}
                value={attributes[key].value}
                name="value"
                onChange={handleChange}
              />
              <button
                className="text-red-600 text-start"
                type="button"
                data-key={key}
                onClick={handleDelete}
              >
                Eliminar
              </button>
            </div>
          );
        })}
      </div>
      <button onClick={handleAdd}>
        <i className="fas fa-plus"></i>
      </button>
    </div>
  );
};

export default DynamicAttributes;
