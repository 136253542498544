import styled from "styled-components";

const Ul = styled.ul`
  display: block;
  list-style: none;
  padding: 0;
  margin: 0 auto;

  &.top-pad {
    padding-top: 30px;
  }
`;

const Li = styled.li`
  display: inline;
  overflow-x: hidden;

  & img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100vh;
  }
`;

const Container = styled.div`
  position: relative;
`;

const Arrow = styled.button`
  font-size: 8vw;
  overflow: hidden;
  color: transparent;
  background-color: transparent;
  border-color: transparent;

  height: 100%;
  width: 30%;
  position: absolute;

  transition: all 0.3s;

  &.left {
    left: 0;
    text-align: left;
  }

  &.right {
    right: 0;
    text-align: right;
  }

  &:hover {
    background: rgba(168, 168, 168, 0.3);
    color: rgba(0, 0, 0, 0.5);
  }
`;

export { Ul, Li, Arrow, Container };
