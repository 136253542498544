import { useState } from "react";
import Modal from "react-modal";
import { GridContacts } from "./common/contact-links";
import styled from "styled-components";
import Showroom from "./showroom";
import ContactUs from "./contactUs";
import { Services2 } from "./services2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#614051",
    border: "5px solid #614051",
    borderRadius: "10px",
    maxWidth: "80%",
    color: "white",
  },
};

const CloseButton = styled.span`
  position: absolute;
  right: 17px;
  top: 5px;
  cursor: pointer;
  font-size: 30px;
  color: white;
  text-align: center;
`;

const Home = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Services2 handleOpenModal={openModal} />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <CloseButton onClick={closeModal}>
          <i className="fas fa-times"></i>
        </CloseButton>
        <h2
          className="text-center mb-4 text-xl font-semibold"
        >
          Contacte-nos para preços, orçamentos ou outras informações!
        </h2>
        {GridContacts()}
      </Modal>

      <Showroom
        theme={props.theme.showroom}
        history={props.history}
      />
      <ContactUs theme={props.theme.contactUs} />
    </>
  );
};

export default Home;
