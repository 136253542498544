import React from "react";
import styled from "styled-components";

const PriceContainer = styled.div`
  margin: 15px auto 0px auto;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 15px;
`;

const Price = styled.span`
  text-align: center;
  color: ${(props) => props.badge.fg};
  font-size: 2em;
  font-weight: 600;
  background-color: ${(props) => props.badge.bg};
  border-radius: 3px;
  padding: 5px;

  &.disabled {
    background-color: gray;
  }
`;

const PriceBadge = (props) => {
  return (
    <PriceContainer>
      <Price className={props.sold ? "disabled" : ""} badge={props.badge}>
        {props.price}€
      </Price>
    </PriceContainer>
  );
};

export default PriceBadge;
