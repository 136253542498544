import { useEffect, useState } from "react";
import axios from "axios";
import { beUrl } from "../../config";
import CarForm from "./CarForm";
import { toast } from "../common/helper";
import { useHistory } from "react-router-dom";

const AddForm = (props) => {
  const [car, setCar] = useState(undefined);

  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`${beUrl}/cars`);
      setCar({
        title: "",
        id: data.length + 1,
        price: 0,
        showroom: false,
        sold: false,
        images: [],
        attributes: {
          kms: { value: "", description: "KMs" },
          year: { value: "", description: "Ano" },
          month: { value: "", description: "Mês" },
          brand: { value: "", description: "Marca" },
          model: { value: "", description: "Modelo" },
          fuelType: { value: "", description: "Combustível" },
          motorCapacity: { value: "", description: "Cilindrada" },
          gearType: { value: "", description: "Mudanças" },
          numberOfGears: { value: "", description: "Mudanças" },
          hp: { value: "", description: "Cavalos" },
          doors: { value: "", description: "Portas" },
        },
      });
    }

    fetchData();
  }, []);

  return car ? (
    <CarForm
      buttonText="Adicionar"
      car={car}
      callback={async (formData) => {
        await axios
          .post(`${beUrl}/cars`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-auth-token": localStorage.getItem("token"),
            },
          })
          .then(() => {
            toast.success("Viatura adicionada com sucesso");
            history.push("/admin/list");
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }}
    />
  ) : (
    <h1>Loading...</h1>
  );
};

export default AddForm;
