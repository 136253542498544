import { useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { Ul, Li, Arrow, Container } from "./carousel-styled-components";

const getPhotos = (images, selected) => {
  return (
    <Li key={images[selected]}>
      <img
        src={images[selected]}
        alt={`Foto ${images[selected]} da viatura`}
        draggable={false}
      />
    </Li>
  );
};

const setThumbnailBoxScroll = (selectedIndex) => {
  const target = document.getElementById("selectedThumb");
  if (target !== null && target.parentNode !== undefined) {
    const style = getComputedStyle(target);
    const spacing = parseInt(style.marginRight);
    target.parentNode.scrollLeft =
      (target.clientWidth + spacing) * (selectedIndex - 1);
  }
};

const Carousel = (props) => {
  const { car, fullscreen, selectedIndex, setSelectedIndex } = props;
  const images = car.images;
  const containerRef = useRef(null);
  const targetRef = useRef(null);

  useEffect(() => {
    scrollToTarget();
  }, [images]);

  const scrollToTarget = () => {
    if (containerRef.current && targetRef.current) {
      containerRef.current.scrollLeft = targetRef.current.offsetLeft;
    }
  };

  const next = () => {
    const newIndex = selectedIndex < images.length - 1 ? selectedIndex + 1 : 0;
    setSelectedIndex(newIndex);
    setThumbnailBoxScroll(newIndex);
  };

  const previous = () => {
    const newIndex = selectedIndex > 0 ? selectedIndex - 1 : images.length - 1;
    setSelectedIndex(newIndex);
    setThumbnailBoxScroll(newIndex);
  };

  const getThumbnails = (car, selected) => {
    return images.map((imageSrc, i) => {
      return (
        <span
          key={imageSrc}
          className={`border-2 ${
            selected === i
              ? "border-vinculo-pumpkin"
              : "border-transparent opacity-50"
          } cursor-pointer`}
          id={selected === i ? "selectedThumb" : ""}
          ref={selected === i ? targetRef : null}
        >
          <img
            src={imageSrc}
            alt={`Foto ${imageSrc} da viatura`}
            onClick={() => setSelectedIndex(i)}
            draggable={false}
            className="w-[100px] min-w-[100px]"
          />
        </span>
      );
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => previous(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return images.length > 0 ? (
    <div
      style={{
        gridArea: "carousel",
      }}
      className={`${
        fullscreen && "bg-black h-screen flex flex-col justify-center"
      }  relative`}
    >
      <Container id="carousel-container">
        <Arrow {...handlers} onClick={previous} className="left">
          <i className="fas fa-chevron-left"></i>
        </Arrow>

        <Arrow {...handlers} onClick={next} className="right">
          <i className="fas fa-chevron-right"></i>
        </Arrow>

        <Ul {...handlers}>{getPhotos(images, selectedIndex)}</Ul>
      </Container>

      <div
        className={`flex ${
          fullscreen && "w-screen"
        } absolute left-0 right-0 bottom-0 overflow-scroll`}
        ref={containerRef}
      >
        {getThumbnails(car, selectedIndex)}
      </div>
    </div>
  ) : (
    <h2 className="text-3xl">Ainda não existem fotos para esta viatura.</h2>
  );
};

export default Carousel;
