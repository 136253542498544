import { useEffect, useState } from "react";
import axios from "axios";
import { beUrl } from "../../config";
import CarForm from "./CarForm";
import { useHistory } from "react-router-dom";
import { toast } from "../common/helper";

const EditForm = (props) => {
  const [car, setCar] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dropZoneIndex, setDropZoneIndex] = useState(-1);
  const id = props.match.params.id;
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      await axios
        .get(`${beUrl}/cars/${id}`)
        .then(function ({ data }) {
          setCar(data);
        })
        .catch(function (err) {
          setError(err.message);
        });
    }

    fetchData();
  }, [id]);

  const handleDelete = (index) => {
    const newCar = { ...car };
    newCar.images.splice(index, 1);
    setCar(newCar);
  };

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
    setDraggedIndex(index);
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    setDropZoneIndex(index);
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();
    const dragIndex = event.dataTransfer.getData("text/plain");
    const updatedImages = [...car.images];
    const draggedImage = updatedImages.splice(dragIndex, 1)[0];
    updatedImages.splice(dropIndex, 0, draggedImage);
    car.images = updatedImages;
    setDraggedIndex(null); // Reset the dragged index
    setDropZoneIndex(-1);
  };

  if (error) {
    return <span>Erro: {error}</span>;
  }

  return car ? (
    <>
      <CarForm
        buttonText="Guardar"
        car={car}
        callback={async (formData) => {
          await axios
            .patch(`${beUrl}/cars/${id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                "x-auth-token": localStorage.getItem("token"),
              },
            })
            .then(() => {
              history.push("/admin/list");
              toast.success("Alterações guardada com sucesso");
            })
            .catch((err) => {
              toast.error(err.message);
            });
        }}
      />

      <div className="p-4">
        <div className="flex gap-2 flex-wrap">
          {car.images.map((image, idx) => {
            return (
              <div
                key={`$image-${idx}`}
                className={`flex border-4 ${
                  idx === draggedIndex && "border-green-500"
                }`}
                draggable
                onDragStart={(event) => handleDragStart(event, idx)}
                onDragOver={(event) => handleDragOver(event, idx)}
                onDrop={(event) => handleDrop(event, idx)}
              >
                <img
                  className="w-28"
                  src={image}
                  alt={`Foto ${image} da viatura`}
                  draggable={false}
                />
                <button
                  className="bg-red-500 hover:bg-red-600 text-white p-1"
                  onClick={() => handleDelete(idx)}
                >
                  <i className="fa fa-trash text-md" />
                </button>

                {idx === dropZoneIndex && idx !== draggedIndex && (
                  <div className="w-10 ml-2 border-4 border-dotted border-black"></div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  ) : (
    <h1>Loading...</h1>
  );
};

export default EditForm;
