const baseTheme = {
  bgColor: "#EAE7DC",
  badge: {
    bg: "#BF4D28",
    fg: "#ffffff",
  },
  primaryText: "black",
  secundaryText: "#ffffff",
};

const cardBase = {
  bgColor: "#54130F",
  bgHover: "#BF4D28",
  borderColor: "#FFFFFF",
  borderHover: "#FFFFFF",
  primaryText: "white",
  secundaryText: "#FF90DD",
  primaryTextHover: "white",
  secundaryTextHover: "white",
  soldBadgeBg: "#614051",
  soldBadgeFg: "#FFFFFF",
};

const buttons = {
  buttonBgColor: "#54130F",
  buttonFgColor: "white",
  badgeBgColor: "#F5A876",
  badgeFgColor: "white",
  activeBgColor: "#BF4D28",
  activeFgColor: "white",
};

export const theme = {
  ...baseTheme,

  card: {
    ...cardBase,
  },

  services: {
    bg: "#F9CBB1",
    bgHover: "#F5883E",
    cardBg: "white",
    cardFg: "black",
    fgHover: "white",
    primary: "#54130F",
    secundary: "black",
  },

  contactUs: {
    bg: "#ffffff",
    primary: "#000000",
    secundary: "#000000",
  },

  showroom: {
    bg: "#F5883E",
    primary: "#ffffff",
    secundary: "#ffffff",

    card: { ...cardBase, borderHover: "#FFFFFF" },
    ...baseTheme,
  },

  filters: { ...buttons },

  pagination: { ...buttons },
};

export const backupTheme = {
  bgColor: "#181F23",
  bgSecundaryColor: "#31444B",
  bgHover: "#222C31",
  buttonPrimary: "#0C7A7B",
  badge: "#F7F8F9",
  primaryText: "#ffffff",
  secundaryText: "#d7d7d7",
};
