import React from "react";
import styled, { keyframes } from "styled-components";

const An1 = keyframes`
  0% { top: 36px; height: 128px }
  50% { top: 60px; height: 80px }
  100% { top: 60px; height: 80px }
`;

const An2 = keyframes`
  0% { top: 41.99999999999999px; height: 116.00000000000001px }
  50% { top: 60px; height: 80px }
  100% { top: 60px; height: 80px }
`;

const An3 = keyframes`
  0% { top: 48px; height: 104px }
  50% { top: 60px; height: 80px }
  100% { top: 60px; height: 80px }
`;

const Container = styled.div`
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;

  & .ldio-f7qycwv0brs div {
    position: absolute;
    width: 30px;
  }
  .ldio-f7qycwv0brs div:nth-child(1) {
    left: 35px;
    background: #5c1510;
    animation: ${An1} 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.2s;
  }
  & .ldio-f7qycwv0brs div:nth-child(2) {
    left: 85px;
    background: #bf4d28;
    animation: ${An2} 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.1s;
  }
  & .ldio-f7qycwv0brs div:nth-child(3) {
    left: 135px;
    background: #ffb142;
    animation: ${An3} 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: undefineds;
  }

  & .ldio-f7qycwv0brs {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  & .ldio-f7qycwv0brs div {
    box-sizing: content-box;
  }
`;

const LoadingSpinner = (props) => {
  return (
    <Container className="loadingio-spinner-pulse-twcphaqlcxo">
      <div className="ldio-f7qycwv0brs">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Container>
  );
};

export const LoadingContainer = styled.div`
  text-align: center;
`;

export default LoadingSpinner;
