import { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { devices } from "../devices";
import PriceBadge from "./price-badge";
import { Card, ImagePlaceholder } from "./car-card";
import Details from "./details";
import axios from "axios";
import LoadingSpinner, { LoadingContainer } from "./common/loading";
import { beUrl } from "../config";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-auto-rows: minmax(400px, auto);

  @media ${devices.laptopL} {
    max-width: 1000px;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 25px;
  }

  @media ${devices.tablet} {
    max-width: 400px;
    margin: 0 auto;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 2fr) 1fr;
    grid-row-gap: 25px;
  }
}`;

const Showroom = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState(null);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const { data } = await axios.get(`${beUrl}/cars`);
        setCars(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchCars();
  }, []);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const { history } = props;

  return (
    <div className="p-6 bg-vinculo-rustyOrange m-0">
      <h2 className="homeTitle text-white">As nossas Viaturas</h2>
      <h3 className="homeSubtitle text-white">
        Encontre aqui um bom negócio para o seu próximo carro.
      </h3>

      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner></LoadingSpinner>
        </LoadingContainer>
      ) : (
        <Grid>
          {cars
            .filter((car) => car.showroom)
            .map((car) => {
              const mileage = car.attributes.kms;
              const year = car.attributes.year.value;
              const fuelType = car.attributes.fuelType.value;

              return (
                <ThemeProvider theme={props.theme.card} key={car.id}>
                  <Card onClick={() => history.push(`/carro/${car.id}`)}>
                    <div>
                      {isLoading && <ImagePlaceholder></ImagePlaceholder>}
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/img/${car.id}/thumbnail.webp`
                        }
                        alt={"Foto principal da viatura"}
                        onLoad={handleLoad}
                      />
                    </div>
                    <h4 className="card-title">{car.title}</h4>

                    <Details
                      year={year}
                      mileage={mileage}
                      fuelType={fuelType}
                    />

                    <PriceBadge badge={props.theme.badge} price={car.price} />
                  </Card>
                </ThemeProvider>
              );
            })}

          <Card onClick={() => history.push("/carros")}>
            <div className="text-center w-full h-full text-xl table">
              <div className="table-cell align-middle text-center w-full text-white">
                Veja todas as nossas ofertas.{" "}
                <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </Card>
        </Grid>
      )}
    </div>
  );
};

export default Showroom;
