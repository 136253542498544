import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { removeStyle } from "../styles/links";
import { useEffect, useState } from "react";
import { userLogged } from "./common/helper";

const NavBar = () => {
  const [hidden, setHidden] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleMenuClick = () => {
    setHidden(!hidden);
  };

  useEffect(() => {
    setIsAdmin(userLogged() === null ? false : true);
  }, []);

  return (
    <nav
      className="
          flex flex-wrap
          items-center
          justify-between
          w-full
          p-2
          text-lg text-gray-700
          bg-[#EAE7DC]
        "
    >
      <div>
        <Link
          className="brand text-black hover:text-vinculo-rustyOrange"
          to="/"
        >
          <h1>\\ VINCULOBJETIVO</h1>
        </Link>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="menu-button"
        className="h-6 w-6 cursor-pointer md:hidden block"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={() => handleMenuClick()}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>

      <div
        className={`${
          hidden && "hidden"
        } w-full md:flex md:items-center md:w-auto`}
        id="menu"
      >
        <ul
          className="
              p-2
              m-0
              text-base text-gray-700
              md:flex
              md:justify-between 
              md:pt-0"
        >
          <li>
            <Link
              to="/"
              style={{ ...removeStyle, color: "black" }}
              className="mr-2 underline"
            >
              Início
            </Link>
          </li>
          <li>
            <Link
              to="/carros"
              style={{ ...removeStyle, color: "black" }}
              className="mr-2 underline"
            >
              Carros
            </Link>
          </li>
          <li>
            <HashLink
              to="/#contacts"
              style={{ ...removeStyle, color: "black" }}
              className="mr-2 underline"
            >
              Contactos
            </HashLink>
          </li>
          {isAdmin && <><li>
            <Link
              to="/admin/list"
              style={{ ...removeStyle, color: "black" }}
              className="mr-2 underline"
            >
              Administração
            </Link>
          </li>
          <li>
            <span
              style={{ ...removeStyle, color: "black" }}
              className="mr-2 underline"
            >
              <button
                onClick={() => {
                  localStorage.removeItem("token");
                  window.location.reload();
                }}
              >
                Terminar Sessão
              </button>
            </span>
          </li></>}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
