import styled, { keyframes } from "styled-components";

const gradient = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const ImagePlaceholder = styled.div`
  background: linear-gradient(
    -45deg,
    ${(props) => props.theme.card.bgColor},
    ${(props) => props.theme.card.bgHover},
    ${(props) => props.theme.card.borderColor},
    ${(props) => props.theme.card.borderHover}
  );
  background-size: 400% 400%;
  animation: ${gradient} 15s ease infinite;
  width: 100%;
  height: 200px;
`;

export const Card = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.3s;

  border: 3px solid ${(props) => props.theme.card.borderColor};
  border-radius: 10px;

  background-color: ${(props) => props.theme.card.bgColor};
  color: ${(props) => props.theme.primaryText};

  & img {
    display: block;
    margin: 0 auto;
    height: 200px;
    width: 100%;
    object-fit: cover;
  }

  & .bw {
    filter: grayscale(100%);
  }

  &:hover {
    background-color: ${(props) => props.theme.card.bgHover};
    border: 3px solid ${(props) => props.theme.card.borderHover};
    color: ${(props) => props.theme.card.secundaryTextHover};
  }
`;

export const SoldBadge = styled.div`
  margin: 0 auto;
  background-color: ${(props) => props.theme.card.soldBadgeBg};
  border-bottom: 1px solid white;
  color: ${(props) => props.theme.card.soldBadgeFg};
  text-align: center;
`;
