import React, { useEffect, useState } from "react";
import axios from "axios";
import { beUrl } from "../../config";
import { formatKms, formatPrice, toast, userLogged } from "../common/helper";
import { Link, useHistory } from "react-router-dom";

const Inventory = (props) => {
  const [cars, setCars] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (!userLogged()) {
      history.push("/admin/login");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`${beUrl}/cars`);
      setCars(data);
    }
    fetchData();
  }, []);

  const alertDelete = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    var result = confirm("De certeza que deseja apagar permanentemente este carro?");
    if (result) {
      await axios
        .delete(`${beUrl}/cars/${id}`, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          toast.error("Erro ao apagar anúncio.");
        });
    }
  };

  return cars ? (
    <div className="p-4">
      <button
        className="button-secundary no-bg mr-2"
        onClick={() => {
          localStorage.removeItem("token");
          history.push("/admin/login");
        }}
      >
        Terminar Sessão
      </button>
      <button
        className="button-secundary mr-2"
        onClick={() => history.push("/admin/new")}
      >
        Adicionar Anúncio
      </button>

      <table className="w-full mt-2">
        <thead>
          <tr className="border-b-2 border-black text-left">
            <th className="p-2">ID</th>
            <th className="p-2">Título</th>
            <th className="p-2">Data Matrícula</th>
            <th className="p-2">KMs</th>
            <th className="p-2">Preço</th>
            <th className="p-2">Vendido</th>
            <th className="p-2">Destacado</th>
            <th className="p-2">Editar</th>
            <th className="p-2">Apagar</th>
          </tr>
        </thead>
        <tbody>
          {cars.map((car) => {
            return (
              <tr
                key={car.id}
                className="border-b border-black hover:bg-vinculo-paleOrange"
              >
                <td className="p-2">{car.id}</td>
                <td className="p-2">{car.title}</td>
                <td className="p-2">{`${car.attributes.month.value} / ${car.attributes.year.value}`}</td>
                <td className="p-2">{formatKms(car.attributes.kms.value)}</td>
                <td className="p-2">{formatPrice(car.price)}</td>
                <td className="p-2 text-center">
                  {car.sold && (
                    <span className="rounded p-1 bg-vinculo-paleOrange">
                      Vendido
                    </span>
                  )}
                </td>
                <td className="p-2">
                  {car.showroom ? <i className="far fa-check-circle"></i> : ""}
                </td>
                <td className="p-2">
                  <Link to={`/admin/edit/${car.id}`}>
                    <i className="far fa-edit"></i>
                  </Link>
                </td>
                <td className="p-2">
                  <button onClick={() => alertDelete(car.id)}>
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <h1>Loading...</h1>
  );
};

export default Inventory;
