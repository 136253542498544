import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import LoadingSpinner, { LoadingContainer } from "./common/loading";
import { beUrl } from "../config";

const Filter = ({
  showSold,
  selectedBrands,
  theme,
  toggleVisibility,
  expanded,
  onDisableAvailableFilter,
  onClickAvailable,
  onClickBrand,
}) => {
  const [allBrands, setAllBrands] = useState([]);
  const [cars, setCars] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data: cars } = await axios.get(`${beUrl}/cars`);

      let brands = [];
      cars.forEach((car) => {
        const b = car.attributes.brand.value;
        if (!brands.includes(b)) {
          brands.push(b);
        }
      });
      brands = _.sortBy(brands);

      setAllBrands(brands);
      setCars(cars);
    };

    fetchData();
  }, []);

  useEffect(() => {
    function handleResize() {
      if(window.innerWidth > 640) setVisible(true);
    }

    if(window.innerWidth > 640) setVisible(true);

    // Add event listener when component mounts
    window.addEventListener('resize', handleResize);

    // Remove event listener when component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const countBrand = (brand) => {
    return cars.filter((c) => c.attributes.brand.value === brand).length;
  };

  const countSold = () => {
    return cars.filter((c) => c.sold).length;
  };

  const handleToggle = () => {
    setVisible(!visible);
  };

  if (allBrands.length === 0 || cars.length === 0) {
    return (
      <LoadingContainer>
        <LoadingSpinner></LoadingSpinner>
      </LoadingContainer>
    );
  }

  const active = "bg-vinculo-rustyOrange";

  return (
    <>
      <button onClick={handleToggle} className="button-primary sm:hidden">
        {visible? 'Esconder filtros' : 'Mostrar Filtros'}<i className={`ml-2 fa fa-angle-${visible ? "up" : "down"}`} />
      </button>

      {visible && (
        <div className="flex self-center sm:self-auto flex-col space-y-5 min-w-[200px]">
          <div id="filters">
            <h4 className="text-xl mb-2 font-semibold">Filtros</h4>
            <div className="bg-vinculo-pumpkin rounded-lg">
              <button
                className={`filter-button ${showSold && active}`}
                onClick={onDisableAvailableFilter}
              >
                Todos
                <span className="badge">{cars.length}</span>
              </button>

              <button
                className={`filter-button ${showSold || active}`}
                onClick={() => onClickAvailable()}
              >
                Disponíveis
                <span className="badge">{cars.length - countSold()}</span>
              </button>
            </div>
          </div>

          <div id="brands">
            <h4 className="text-xl mb-2 font-semibold">Marcas</h4>
            <div className="bg-vinculo-pumpkin rounded-lg">
              <button
                className={`filter-button ${
                  selectedBrands.length === 0 && active
                }`}
                onClick={() => onClickBrand("all")}
              >
                Todas
                <span className="badge">{cars.length}</span>
              </button>
              {allBrands.map((brand) => {
                return (
                  <button
                    key={brand}
                    className={`filter-button ${
                      selectedBrands.includes(brand) && active
                    }`}
                    onClick={() => onClickBrand(brand)}
                  >
                    {brand}
                    <span className="badge">{countBrand(brand)}</span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;
