import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DynamicAttributes from "./DynamicAttributes";
import { userLogged } from "../common/helper";

const CarForm = ({ car, buttonText, callback }) => {
  const [selectedCar, setSelectedCar] = useState(car);
  const [attributes, setAttributes] = useState(car.attributes);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (!userLogged()) {
      history.push("/admin/login");
    }
  }, [history]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const c = { ...selectedCar };

    c.attributes = { ...attributes };
    Object.keys(c.attributes).forEach((key) => {
      if (
        c.attributes[key]["value"] === "" &&
        c.attributes[key]["description"] === ""
      ) {
        delete c.attributes[key];
      }
    });

    setError(undefined);

    // Create FormData object
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    const jsonData = JSON.stringify(c);
    formData.append('car', jsonData);

    callback(formData);
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);

    setError(undefined);

    for (let file of fileList) {
      // Check if the selected file is an image
      if (!file.type.startsWith("image/")) {
        setError("Nem todos os ficheiros selecionados são imagens.");
        event.target.value = null; // Clear the selected file
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        //limit is also enforced on the BE
        setError("O limite the tamanho por ficheiro é de 5MB.");
        event.target.value = null; // Clear the selected file
        return;
      }
    }

    setFiles(fileList);
  };

  const handleChange = ({ currentTarget: input }) => {
    const c = { ...selectedCar };
    c[input.name] = input.value;
    setSelectedCar(c);
  };

  const handleChangeAttributes = (attributeDesc, { currentTarget: input }) => {
    const c = { ...selectedCar };
    c.attributes[input.name] = {
      value: input.value,
      description: attributeDesc,
    };
    setSelectedCar(c);
  };

  return selectedCar ? (
    <div className="p-4">
      <button
        className="button-secundary mb-4"
        onClick={() => {
          history.push("/admin/list");
        }}
      >
        <i className="fas fa-arrow-left"></i> Voltar à lista
      </button>
      <form className="car-form">
        <div className="form-group">
          <div className="form-row">
            <div className="flex grow">
              <label htmlFor="title">Título</label>
              <input
                value={selectedCar.title}
                onChange={handleChange}
                type="text"
                name="title"
                id="title"
                className="grow"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="form-row">
            <div className="flex grow">
              <label htmlFor="id">Id</label>
              <input
                value={selectedCar.id}
                onChange={handleChange}
                type="number"
                name="id"
                id="id"
                className="grow"
                disabled
              />
            </div>
            <div className="flex grow">
              <label htmlFor="sold">Vendido</label>
              <select
                value={selectedCar.sold}
                onChange={handleChange}
                name="sold"
                className="grow"
                id="sold"
              >
                <option value="false">Não</option>
                <option value="true">Sim</option>
              </select>
            </div>
            <div className="flex grow">
              <label htmlFor="showroom">Destaque Pág. Inicial</label>
              <select
                value={selectedCar.showroom}
                onChange={handleChange}
                name="showroom"
                className="grow"
                id="showroom"
              >
                <option value="false">Não</option>
                <option value="true">Sim</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="form-row">
            <div className="flex grow">
              <label htmlFor="price">Preço</label>
              <input
                type="number"
                value={selectedCar.price}
                onChange={handleChange}
                name="price"
                id="price"
                className="grow"
              />
            </div>
            <div className="flex grow">
              <label htmlFor="kms">Quilómetros</label>
              <input
                type="number"
                value={selectedCar.attributes.kms.value}
                onChange={(e) => handleChangeAttributes("KMs", e)}
                name="kms"
                id="kms"
                className="grow"
              />
            </div>

            <div className="flex grow">
              <label htmlFor="year">Ano</label>
              <input
                type="number"
                value={selectedCar.attributes.year.value}
                onChange={(e) => handleChangeAttributes("Ano", e)}
                name="year"
                id="year"
                className="grow"
              />
            </div>
            <div className="flex grow">
              <label htmlFor="month">Mês</label>
              <input
                type="text"
                value={selectedCar.attributes.month.value}
                onChange={(e) => handleChangeAttributes("Mês", e)}
                name="month"
                id="month"
                className="grow"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="form-row">
            <div className="flex grow">
              <label htmlFor="brand">Marca</label>
              <input
                type="text"
                value={selectedCar.attributes.brand.value}
                onChange={(e) => handleChangeAttributes("Marca", e)}
                name="brand"
                id="brand"
                className="grow"
              />
            </div>
            <div className="flex grow">
              <label htmlFor="model">Modelo</label>
              <input
                type="text"
                value={selectedCar.attributes.model.value}
                onChange={(e) => handleChangeAttributes("Modelo", e)}
                name="model"
                test="hhhh"
                id="model"
                className="grow"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="form-row">
            <div className="flex grow">
              <label htmlFor="fuelType">Combustível</label>
              <input
                type="text"
                value={selectedCar.attributes.fuelType.value}
                onChange={(e) => handleChangeAttributes("Combustível", e)}
                name="fuelType"
                id="fuelType"
                className="grow"
              />
            </div>

            <div className="flex grow">
              <label htmlFor="motorCapacity">Cilindrada</label>
              <input
                type="number"
                value={selectedCar.attributes.motorCapacity.value}
                onChange={(e) => handleChangeAttributes("Cilindrada", e)}
                name="motorCapacity"
                id="motorCapacity"
                className="grow"
              />
            </div>

            <div className="flex grow">
              <label htmlFor="hp">Cavalos</label>
              <input
                type="number"
                value={selectedCar.attributes.hp.value}
                onChange={(e) => handleChangeAttributes("Cavalos", e)}
                name="hp"
                id="hp"
                className="grow"
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="form-row">
            <div className="flex grow">
              <label htmlFor="doors">Portas</label>
              <input
                type="number"
                value={selectedCar.attributes.doors.value}
                onChange={(e) => handleChangeAttributes("Portas", e)}
                name="doors"
                id="doors"
                className="grow"
              />
            </div>
            <div className="flex grow">
              <label htmlFor="gearType">Tipo de Mudanças</label>
              <select
                value={selectedCar.attributes.gearType.value}
                onChange={(e) => handleChangeAttributes("Mudanças", e)}
                name="gearType"
                className="grow"
                id="gearType"
              >
                <option value=""></option>
                <option value="automático">Automático</option>
                <option value="manual">Manual</option>
              </select>
            </div>
            <div className="flex grow">
              <label htmlFor="numberOfGears">Número de Mudanças</label>
              <input
                type="number"
                value={selectedCar.attributes.numberOfGears?.value}
                onChange={(e) => handleChangeAttributes("# Mudanças", e)}
                name="numberOfGears"
                id="numberOfGears"
                className="grow"
              />
            </div>
          </div>
        </div>

        <DynamicAttributes
          attributes={attributes}
          setAttributes={setAttributes}
        />

        <div>
          <h3 className="text-lg font-semibold mb-2">Imagens</h3>
          <input
            className="block my-2"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <label className="text-red-500 block">{error}</label>
        </div>

        <button className="button-primary self-start" onClick={handleSubmit}>
          {buttonText}
        </button>
      </form>
    </div>
  ) : (
    <span>Loading...</span>
  );
};

export default CarForm;
