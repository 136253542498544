import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import PriceBadge from "./price-badge";
import Details from "./details";
import { Card, ImagePlaceholder } from "./car-card";

const CarListItem = (props) => {
  const car = props.car;
  const { title, price, id, sold, images } = car;

  const mileage = car.attributes.kms;
  const year = car.attributes.year.value;
  const fuelType = car.attributes.fuelType.value;

  const history = useHistory();

  return (
    <ThemeProvider theme={props.theme.card}>
      <Card onClick={() => history.push(`/carro/${id}`)}>
        <div className="relative">
          {images.length !== 0 ? (
            <img
              src={images[0]}
              alt="car"
              className={`${sold && "grayscale"}`}
            />
          ) : (
            <ImagePlaceholder></ImagePlaceholder>
          )}

          <div
            className={`${
              sold || "hidden"
            } border-b-2 bg-vinculo-rustyOrange absolute bottom-0 w-full`}
          >
            <strong className="block text-center">Vendido</strong>
          </div>
        </div>

        <h4 className="card-title">{title}</h4>

        <Details year={year} mileage={mileage} fuelType={fuelType} />

        <PriceBadge badge={props.theme.badge} price={price} sold={sold} />
      </Card>
    </ThemeProvider>
  );
};

export default CarListItem;
