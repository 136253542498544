import { useState } from "react";
import { beUrl } from "../../config";
import axios from "axios";
import { toast } from "../common/helper";

const useForm = (subject, setValues, values) => {
  const [errors, setErrors] = useState({});
  const [emptyContacts, setEmptyContacts] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkErrors = (errors, name) => {
    if (Object.keys(errors).includes(name)) {
      return <small className="text-red-500 block">{errors[name]}</small>;
    }
  };

  const validateEmail = (mail) =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    );

  const validateMobile = (mobile) =>
    /^\d{9}$/.test(mobile) || /^\+\d{12}$/.test(mobile);

  const validate = (values) => {
    const err = {};

    if (!validateEmail(values.email) && values.mobile === "") {
      err["no_contacts"] = "Nenhum contacto foi preenchido.";
    }

    if (values.name === "") {
      err["name"] = "Por favor, indique o seu nome";
    }

    if (values.text === "") {
      err["text"] = "Coloque aqui as suas dúvidas ou questões";
    }

    return err;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => {
      const v = {
        ...values,
        [name]: value,
      };

      if (name === "email" || name === "mobile") {
        setEmptyContacts(
          !validateEmail(v["email"]) && !validateMobile(v["mobile"])
        );
      }

      return v;
    });
  };

  const submitAndClean = async () => {
    await axios.post(`${beUrl}/messages/send`, values);
    setValues({
      subject,
      mobile: "",
      name: "",
      email: "",
      text: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const err = validate(values);
    setErrors(err);
    setEmptyContacts(Object.keys(err).includes("no_contacts"));

    if (Object.keys(err).length === 0) {
      try {
        submitAndClean();
        toast.success("A sua mensagem foi enviada. Obrigado!");
      } catch {
        toast.error(
          "Ocorreu um erro ao enviar a sua mensagem. Por favor tente mais tarde."
        );
      }
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
    }
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
    emptyContacts,
    validate,
    validateEmail,
    validateMobile,
    checkErrors,
  };
};

export default useForm;
